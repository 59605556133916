<template>
  <entity-card
    :tabs="tabs"
    :tabs-items="tabsItems"
    :title="item.name"
    :image="image"
    :actions="actions"
    :loading="loading"
    :show-back-btn="canBack"
    @click:close="handleClose"
    @click:back="handleBack"
  >
    <template #header:status-bar>
      <div class="mr-2" :class="{ 'red--text': !item.enabled }">
        {{ item.enabled ? 'Enabled' : 'Disabled' }}
      </div>
    </template>
  </entity-card>
</template>

<script>
import EntityCard from '@/components/entity-card';
import {
  computed,
  onMounted,
  provide,
  toRefs,
  watch
} from '@vue/composition-api';

import { useGeozoneMenu } from '@/modules/geozones/compositions/geozoneMenu';

import { useGeozone } from '@/modules/geozones/compositions/geozone';
import { useMap } from '@/compositions/map';
import { useGeozoneObjects } from '../../compositions/geozoneObjects';
import useHistoryStack from '@/compositions/history-stack';

export default {
  name: 'GeozoneCard',
  components: {
    EntityCard
  },
  props: {
    geozoneId: {
      type: String,
      required: true
    }
  },
  setup(props, { emit }) {
    const { geozoneId } = toRefs(props);

    const { hasPrevRoute: canBack } = useHistoryStack();

    const {
      geozones,
      markers: { setMarkers }
    } = useMap();

    const { item, load, loading } = useGeozone(geozoneId);
    const {
      objectList,
      load: loadObjects,
      loading: loadingGeozoneObjects
    } = useGeozoneObjects(geozoneId);

    const image = computed(() => item.value.url || '/img/object.png');

    provide('entity', item);
    provide('entityId', props.geozoneId);
    provide('geozoneObjects', objectList);
    provide('loadingGeozoneObjects', loadingGeozoneObjects);

    watch(
      () => item.value,
      value => {
        geozones.setGeozones([value]);
        geozones.setMapToGeozoneCenter(geozoneId.value);
      }
    );

    watch(
      () => objectList.value,
      value => {
        setMarkers(value);
      }
    );

    onMounted(async () => {
      load();
      loadObjects();
    });

    const tabs = [
      {
        id: 'general',
        title: 'General'
      },
      {
        id: 'objects',
        title: 'Objects'
      },
      {
        id: 'geo',
        title: 'Geo'
      },
      // {
      //   id: 'media',
      //   title: 'Media'
      // },
      {
        id: 'notifications',
        title: 'Notifications'
      }
      // {
      //   id: 'access',
      //   title: 'Access'
      // }
    ];
    const tabsItems = [
      () => import('./general/index'),
      () => import('./objects/index'),
      {
        component: () => import('@/modules/objects/ui/object-card/geo/index'),
        props: { onlyGeotags: true, injectObjectKey: 'entity' }
      },
      {
        component: () =>
          import('@/modules/notifications/ui/RecentlyNotificationList'),
        props: { objectId: props.geozoneId }
      }
      // () => import('./access/index')
    ];

    const itemActions = computed(() => useGeozoneMenu({ entity: item.value }));

    const handleClose = () => {
      geozones.unselectGeozone(geozoneId.value);
      emit('stack:close');
    };
    const handleBack = () => {
      emit('stack:back');
    };

    return {
      tabs,
      image,
      actions: itemActions,
      handleClose,
      handleBack,
      canBack,
      tabsItems,
      loading,
      item
    };
  }
};
</script>
